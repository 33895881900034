/** @jsx jsx */
/* tslint:disable */
import { css } from '@emotion/core';
import { FC, useState } from 'react';
import { graphql } from 'gatsby';
import { Box, Heading, Flex, Button, Text } from 'rebass';
import { jsx } from 'theme-ui';
import { Input, Label } from '@rebass/forms';
import addToMailchimp from 'gatsby-plugin-mailchimp';

export interface EmailProps {
  className?: string;
  id?: string;
  data: {
    text: string;
    thankYouText: string;
  };
  css?: any;
}

export const query = graphql`
  fragment EmailSection on ContentfulEmailSubscriptionForm {
    text
    thankYouText
    internal {
      type
    }
  }
`;

const validateEmail = (email)  =>{
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

const EmailSection: FC<EmailProps & JSX.IntrinsicElements['div']> = ({
  className = '',
  id = '',
  data,
  ...rest
}) => {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(true);
  const [firstName, setFirstName] = useState('');
  const [firstNameError, setFirstNameError] = useState(true);
  const [lastName, setLastName] = useState('');
  const [lastNameError, setLastNameError] = useState(true);
  const [triedToSubmit, setTriedToSubmit] = useState(false);

  const [submitted, setSubmitted] = useState(false);


  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setTriedToSubmit(true);

    const listFields = {
      FName: firstName,
      LName: lastName
    };

    if (firstName.length > 2) {
      setFirstNameError(false);
    }

    if (lastName.length > 2) {
      setLastNameError(false);
    }

    if (email.length > 2 && validateEmail(email)) {
      setEmailError(false);
    }
    
    if (!firstNameError && !lastNameError && !emailError) {
      // @ts-ignore
      addToMailchimp(email, listFields)
      .then(() => {
        setSubmitted(true);
      })
      .catch((error: Error) => {
        // Errors in here are client side
        // Mailchimp always returns a 200
      });
    }
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.currentTarget.value);
  };
  const handleFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFirstName(event.currentTarget.value);
  };
  const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLastName(event.currentTarget.value);
  };

  const { text, thankYouText } = data;
  if (!submitted) {
    return (
      <Box as="form" onSubmit={handleSubmit}>
        <Heading variant="subheading" color="ourWhite" maxWidth='400px'>{text}</Heading>
        <Flex maxWidth={["100%", "350px"]} justifyContent="space-between">
          <Flex maxWidth='50%' flex="0 1 48%" flexWrap="wrap">
          <Label  color='ourWhite' htmlFor='firstName' maxWidth='100%'
            flex="0 1 100%">
              First name</Label>
          <Input
            id='firstName'
            name='firstName'
            placeholder='First name'
            sx={{
              borderRadius: '4px',
              borderColor: 'ourWhite',
              '&::placeholder': {
                color: 'ourWhite'
              }
            }}
            maxWidth='100%'
            flex="0 1 100%"
            onChange={handleFirstNameChange}
            mr={2}
          />
          </Flex>
          <Flex maxWidth='50%' flex="0 1 48%" flexWrap="wrap">
            <Label  color='ourWhite'  htmlFor='firstName' maxWidth='100%' flex="0 1 100%"> Last name</Label>
            <Input
              id='lastName'
              name='lastName'
              placeholder='Last name'
              sx={{
                borderRadius: '4px',
                borderColor: 'ourWhite',
                color: 'ourWhite',
                '&::placeholder': {
                  color: 'ourWhite'
                }
              }}
              maxWidth='100%'
              flex="0 1 100%"
              onChange={handleLastNameChange}
            />
          </Flex>
        </Flex>
        <Flex>
          {firstNameError && triedToSubmit && (
            <Box><Text as="p">Please enter your first name</Text></Box> )}
          {lastNameError && triedToSubmit && (
            <Box><Text as="p">Please enter your last name</Text></Box> )}
        </Flex>
        <Label color='ourWhite'  htmlFor='email' mt={2}>Email</Label>
        <Flex wrap='noWrap' mt={1} maxWidth={["100%", "350px"]}>
          <Input
            id='email'
            name='email'
            placeholder='hello@knechtincometaxservice.com'
            sx={{
              borderRadius: '4px 0  0 4px',
              borderColor: 'ourWhite',
              '&::placeholder': {
                color: 'ourWhite'
              }
            }}
            maxWidth='75%'
            flex="0 1 100%"
            onChange={handleEmailChange}
          />
          <Button
            variant='primary'
            sx={{
              borderRadius: '0 4px 4px 0',
              paddingLeft: '25px'
            }}
            type="submit"
            name="submit"
          >
            Submit
          </Button>
        </Flex>
        <Flex>
        {emailError && triedToSubmit && (
            <Box><Text  color='ourWhite' as="p">Please enter your email</Text></Box> )}
        </Flex>
      </Box>
    );
  }
  return (
    <Box>
      <Heading variant="subheading" color='ourWhite' maxWidth='400px'>{thankYouText}</Heading>
    </Box>
  );
};

export default EmailSection;
