/** @jsx jsx */
import { FC } from 'react';
import { jsx } from '@emotion/core';
import { graphql } from 'gatsby';

import {
  Link as RebassLink,
  Button,
  LinkProps as RebassLinkProps,
  ButtonProps
} from 'rebass';

export const query = graphql`
  fragment Links on ContentfulLinkButton {
    id
    styledAs
    emphasis
    slug
    visibleText
    newTab
    internal {
      type
    }
  }
`;

export interface LinkProps extends RebassLinkProps {
  id?: string;
  disabled?: boolean;
  styledAs?: boolean;
  emphasis?: boolean;
  slug?: string;
  newTab?: boolean;
  visibleText?: string;
  plain?: boolean;
}

const Link: FC<JSX.IntrinsicElements['a'] & LinkProps & ButtonProps> = ({
  children,
  styledAs,
  emphasis,
  slug,
  newTab,
  plain = false,
  ...rest
}) => {
  const getVariant = () => {
    let variant = 'primaryLink';

    if (!emphasis && !plain) {
      variant = 'secondaryLink';
    } else if (!emphasis && plain) {
      variant = 'plainLink';
    }
    return variant;
  };

  return styledAs || plain ? (
    <RebassLink
      href={slug}
      variant={getVariant()}
      {...(newTab && { target: '_blank', rel: 'noreferrer' })}
      data-js-gtm-link-track={`website-slug--${slug.replace('/', '')}`}
      {...rest}
    >
      {children}
    </RebassLink>
  ) : (
    <Button
      as="a"
      href={slug}
      variant={emphasis ? 'primary' : 'secondary'}
      {...(newTab && { target: '_blank', rel: 'noreferrer' })}
      data-js-gtm-button-track={
        slug ? `website-slug--${slug.replace('/', '')}` : 'empty-link'
      }
      {...rest}
    >
      {children}
    </Button>
  );
};

export default Link;
