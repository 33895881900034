/** @jsx jsx */
import { css } from '@emotion/core';
import { FC } from 'react';
import { graphql } from 'gatsby';
import { Flex, Box, Heading } from 'rebass';
import { jsx } from 'theme-ui';
import EmailSection, { EmailProps } from './emailSection';
import CtaSection, { CTAProps } from './ctaSection';
import Link from '../../ui-components/link/link';

// @ts-ignore
import Facebook from '../../assets/svgs/social-media/facebook.svg';
// @ts-ignore
import Insta from '../../assets/svgs/social-media/insta.svg';
// @ts-ignore
import Linkedin from '../../assets/svgs/social-media/linkedin.svg';
// @ts-ignore
import Twitter from '../../assets/svgs/social-media/twitter.svg';
// @ts-ignore
import YouTube from '../../assets/svgs/social-media/youtube.svg';

interface Props {
  className?: string;
  data: {
    id?: string;
    leftSection: CTAProps | EmailProps;
    rightHeading: string;
  };
  css?: any;
}

export const query = graphql`
  fragment Prefooter on ContentfulPrefooter {
    id
    leftSection {
      ...CtaSection
      ...EmailSection
    }
    rightHeading
    internal {
      type
    }
  }
`;

const Text: FC<Props & JSX.IntrinsicElements['div']> = ({
  className = '',
  data,
  ...rest
}) => {
  const {
    leftSection,
    rightHeading
  } = data;

  // @ts-ignore
  const component = data => {
    // @ts-ignore
    if (leftSection.internal.type === 'ContentfulCtaSection') {
      return <CtaSection data={data} />;
    } else {
      return <EmailSection data={data} />;
    }
  };

  return (
    <Flex overflow="hidden" flexWrap={['wrap', 'wrap', 'nowrap']}>
      <Box
        sx={{ order: [2, 2, 1] }}
        width="100%"
        flex={['0 1 100%', '0 1 100%', '0 1 50%']}
        backgroundColor="orange"
      >
        <Box
          maxWidth={['100%', '100%', '475px']}
          py={[4, 5]}
          ml={[0, 0, 'auto']}
          mr={[0, 0, 0]}
          pl={[3, 3, 0]}
          pr={[3, 3, 0]}
          minHeight={['unset', 'unset', '250px']}
        >
          {component(leftSection)}
        </Box>
      </Box>
      <Box
        sx={{ order: [2, 2, 1] }}
        width="100%"
        flex={['0 1 100%', '0 1 100%', '0 1 50%']}
        backgroundColor="orange"
      >
        <Box
          maxWidth={['100%', '100%', '475px']}
          py={[5, 5]}
          ml={[0, 0, '0']}
          mr={[0, 0, 'auto']}
          pl={[3, 3, 0]}
          pr={[3, 3, 0]}
          minHeight={['unset', 'unset', '250px']}
        >
          <Heading variant="subheading" color="ourWhite">{rightHeading}</Heading>
          <Box my={4} mt="75px">
            <Link
              slug="https://www.facebook.com/1040INCOMETAXSERVICE/"
              newTab
              styledAs="link"
            >
              <Facebook className="social-icons" />
            </Link>
            <Link
              slug="https://www.linkedin.com/company/kitsllc/"
              newTab
              styledAs="link"
            >
              <Linkedin className="social-icons" />
            </Link>
          </Box>
        </Box>
      </Box>
    </Flex>
  );
};

export default Text;
