/** @jsx jsx */
import { css } from '@emotion/core';
import { FC } from 'react';
import { graphql } from 'gatsby';
import Marked from 'marked';
import { Box, Text, Link } from 'rebass';
import MainContainer from '../../ui-components/container/container';
import { jsx } from 'theme-ui';
import { fontFamily } from 'styled-system';

interface Props {
  className?: string;
  data: {
    id?: string;
    text: {
      text: string;
    };
    url: string;
    attribution: string;
    quoteBackground: 'White' | 'Orange' | 'Blue';
  };
  css?: any;
}

export const query = graphql`
  fragment QuoteSection on ContentfulQuote {
    id
    text {
      text
    }
    attribution
    url: link
    quoteBackground: backgroundColor
    internal {
      type
    }
  }
`;

const Quote: FC<Props & JSX.IntrinsicElements['div']> = ({
  className = '',
  data,
  ...rest
}) => {
  const {
    text: { text },
    quoteBackground,
    url,
    attribution
  } = data;

  let computedBackgroundColor;
  let computedQuoteColor;
  let computedTextColor;

  if (quoteBackground === 'White') {
    computedBackgroundColor = 'ourWhite';
    computedQuoteColor = 'blue';
    computedTextColor = 'blue';
  } else if (quoteBackground === 'Orange') {
    computedBackgroundColor = 'orange';
    computedQuoteColor = 'text';
    computedTextColor = 'text';
  } else if (quoteBackground === 'Blue') {
    computedBackgroundColor = 'blue';
    computedQuoteColor = 'white';
    computedTextColor = 'white';
  }

  return (
    <Box bg={computedBackgroundColor}>
      <MainContainer>
        <Box
          flex="0 1 100%"
          maxWidth="calc(100% - 110px)"
          marginY="50px"
          marginX="auto"
          sx={{ textAlign: 'center', width: `calc(100% - 110px)` }}
          as="blockquote"
        >
          <Text
            dangerouslySetInnerHTML={{ __html: Marked(text, { breaks: true }) }}
            sx={{
              color: computedTextColor,
              position: 'relative',
              fontSize: 3,
              '&::before': {
                content: 'open-quote',
                fontSize: '150px',
                color: computedQuoteColor,
                position: 'absolute',
                left: '-55px',
                top: '-55px',
                fontFamily: 'serif'
              }
            }}
          />
          {attribution && url && (
            <Link href={url} color={computedTextColor} target="_blank">
              {attribution}
            </Link>
          )}
          {attribution && !url && (
            <Text color={computedTextColor}>{attribution}</Text>
          )}
        </Box>
      </MainContainer>
    </Box>
  );
};

export default Quote;
