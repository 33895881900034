/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { FC } from 'react';
import { graphql } from 'gatsby';
// @ts-ignore
import Phone from '../../assets/svgs/call.svg';
// import Link from '../../ui-components/link/link';
import MainContainer from '../../ui-components/container/container';

import { Box, Flex, Card, Image, Text, Link, Heading } from 'rebass';

interface Props {
  className?: string;
  id?: string;
  data: {
    title: string;
    subtitle: {
      subtitle: string;
    };
    heading: string;
    link: {
      styledAs: boolean;
      emphasis: boolean;
      slug: string;
      visibleText: string;
    };
    phoneNumber: string;
    includePhoneIcon: boolean;
  };
  titleAs?: 'h1' | 'h2';
  subtitleAs?: 'h1' | 'h2';
  css?: any;
}

export const query = graphql`
  fragment PhoneStripeSection on ContentfulPhoneStripeSection {
    heading
    phoneNumberUrl: link
    phoneNumber
    includePhoneIcon
    internal {
      type
    }
  }
`;

const ParagraphFoundation: FC<Props & JSX.IntrinsicElements['div']> = ({
  className = '',
  id = '',
  titleAs = 'h1',
  subtitleAs = 'h2',
  data: { heading, phoneNumberUrl, phoneNumber, includePhoneIcon },
  ...rest
}) => {
  return (
    <Box bg="blue" py={3}>
      <MainContainer justifyContent="space-between">
        <Heading color="white" as="h2" variant="large">
          {heading}
        </Heading>
        <Heading color="white" ml="auto" as="h2" variant="large">
          {includePhoneIcon && (
            <Box
              margin="auto 20px"
              variant="phoneStripe"
              color="white"
              href={phoneNumberUrl}
            >
              <Link variant="phoneStripe" color="white" href={phoneNumberUrl}>
                <Phone height="20px" style={{ marginTop: ['2px', '8px'], marginRight: '15px' }} />
                {phoneNumber}
              </Link>
            </Box>
          )}
        </Heading>
      </MainContainer>
    </Box>
  );
};

export default ParagraphFoundation;
