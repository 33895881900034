/** @jsx jsx */
import { css } from '@emotion/core';
import { FC } from 'react';
import { graphql } from 'gatsby';
import { Box, Heading } from 'rebass';
import { jsx } from 'theme-ui';
import { LinkProps } from '../../ui-components/link/link';
import Link from '../links/links';

export interface CTAProps {
  className?: string;
  id?: string;
  data: {
    text: string;
    links: [LinkProps];
  };
  css?: any;
}

export const query = graphql`
  fragment CtaSection on ContentfulCtaSection {
    text
    links {
      ...Links
    }
    internal {
      type
    }
  }
`;

const CtaSection: FC<CTAProps & JSX.IntrinsicElements['div']> = ({
  className = '',
  id = '',
  data: { text, links },
  ...rest
}) => (
  <Box>
    <Heading variant="subheading" color="ourWhite">{text}</Heading>
    {links && <Link links={links} />}
  </Box>
);

export default CtaSection;
