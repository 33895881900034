/** @jsx jsx */
import { FC } from 'react';
import { jsx } from '@emotion/core';

import Link, { LinkProps } from '../../ui-components/link/link';
import { Flex } from 'rebass';

export interface LinksProps {
  links: LinkProps[];
}

const Links: FC<LinksProps> = ({ links, ...rest }) => {
  return (
    <Flex
      overflow="hidden"
      flexWrap={['wrap', 'wrap', 'nowrap']}
      alignItems="center"
      width="100%"
    >
      {links.map(({ id, styledAs, emphasis, slug, visibleText, newTab }, i) => (
        <Link
          styledAs={styledAs}
          emphasis={emphasis}
          key={id}
          newTab={newTab}
          {...(slug && { slug: slug })}
          mr={3}
          mb={[2, 0]}
          flex={['0 1 100%', 1, 'unset']}
          maxWidth={['100%', 'unset']}
          {...rest}
        >
          {visibleText}
        </Link>
      ))}
    </Flex>
  );
};

export default Links;
