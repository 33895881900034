/** @jsx jsx */
import { css } from '@emotion/core';
import { FC } from 'react';
import { graphql } from 'gatsby';
import Markdown from 'react-markdown';
import { Box, Flex, Link } from 'rebass';
import MainContainer from '../../ui-components/container/container';
import { jsx } from 'theme-ui';

interface Props {
  className?: string;
  id?: string;
  data: {
    text: {
      text: string;
    };
  };
  css?: any;
}

export const query = graphql`
  fragment TextSection on ContentfulTextSection {
    text {
      text
    }
    internal {
      type
    }
  }
`;

const Text: FC<Props & JSX.IntrinsicElements['div']> = ({
  className = '',
  id = '',
  data,
  ...rest
}) => {
  const {
    text: { text }
  } = data;

  return (
    <MainContainer>
      <Box
        // sx={{ }}
        width="100%"
        flex="0 1 100%"
        maxWidth="100%"
        marginY="25px"
      >
        <Markdown source={text} />
      </Box>
    </MainContainer>
  );
};

export default Text;
